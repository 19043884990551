#messenger-container
{
	width:325px;
	border-radius: 10px;
	border: 1px solid rgb(64,64,65);
	padding:0px;
	font-family: "helvetica nueue", "helvetica", arial, sans-serif;
}
.etelabel {
	background-color: #ffffff;
	padding: '10px';
	padding-left: '5px';
	padding-right: '5px';
	border: 1px solid black;
}
#messenger-header
{
	border-radius: 10px 10px 0px 0px;
	background-color: rgb(64,64,65);
	color: #fff;
	padding:10px;
}
.bolded
{
	font-weight:600;
	color: rgb(64,64,65);
}
.bold-white
{
	font-weight:400;
	color: #fff !important;
}
#close-frame
{
	cursor: pointer;
}
#messages-area
{
	/*background-image: url('sectional.png');*/

	/*background-color: rgb(248,248,248);*/
	background-color:rgba(255,255,255, .4);
	min-height:500px;
	/*padding: 30px;*/
	max-height:500px;
	overflow:scroll;
}

.a-message
{
	background-color: #0d6efd;
    border-radius: 5px;
    padding: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.b-message
{
	background-color: rgb(237, 237, 237);
    border-radius: 5px;
    padding: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.a-message-header
{
	font-size: 12px;
	color: rgb(163,164,174);
	margin-bottom: 20px;
}
.a-message-content
{
	font-size:14px;
	/*color: rgb(155,164,174);*/
	color: white;

}
.b-message-content
{
	font-size:14px;
	/*color: rgb(155,164,174);*/
	color: #212529;

}
#mes-header-left
{
	float:left;
}
#mes-header-right
{
	float:right;
}
#messenger-input
{
	border: none;
	outline:none;
	font-size: 14px;
	color: rgb(178,186,194);
}
input#messenger-input:focus
{
	border:none;
	outline:none;
}
img.rounded
{
	width:30px;
	height:30px;
	border-radius:15px;
	float:left;
	margin-right:10px;
}
img.rounded-close
{
	width:20px;
	height:20px;
	border-radius:10px;
	float:left;
	margin-right:10px;
	margin-top:5px;
}
#header-name
{
	padding-top:7px;
	width:100px;
}
#name
{
	padding-top:7px;
}
#send-area
{
	border-radius: 0px 0px 10px 10px;
	padding:15px 10px 15px 20px;
}
.clearfix
{
	clear: both;
}
img#s
{
	opacity:.1;
}
#sendbutton
{
	margin-left:75px;
	color:rgb(178,186,194);
}
div#gradient
{
	height:30px;
background: rgba(237,237,237,0);
background: -moz-linear-gradient(top, rgba(237,237,237,0) 0%, rgba(255,255,255,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(237,237,237,0)), color-stop(100%, rgba(255,255,255,1)));
background: -webkit-linear-gradient(top, rgba(237,237,237,0) 0%, rgba(255,255,255,1) 100%);
background: -o-linear-gradient(top, rgba(237,237,237,0) 0%, rgba(255,255,255,1) 100%);
background: -ms-linear-gradient(top, rgba(237,237,237,0) 0%, rgba(255,255,255,1) 100%);
background: linear-gradient(to bottom, rgba(237,237,237,0) 0%, rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff', GradientType=0 );
}
#messages-wrapper
{
	padding:30px;
}
